import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css";
import Vuetify from "vuetify";
import moment from "moment";
import "vuetify/dist/vuetify.min.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);
import "../src/assets/js/custom";

Vue.use(Vuetify);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  moment,
  VueSweetalert2,
  vuetify: new Vuetify({
    icons: {
      iconfont: "mdi",
    },
  }),
  render: (h) => h(App),
}).$mount("#app");
