import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../components/login/login"),
  },
  {
    path: "/seller/register",
    name: "Register",
    component: () => import("../components/login/register"),
  },
  {
    path: "/dashboard",
    component: () => import("../components/layouts/Layout"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/pages/Dashboard.vue"),
      },
      {
        path: "/products",
        name: "Products",
        component: () => import("../views/pages/Products.vue"),
      },
      {
        path: "/products/add",
        name: "addProduct",
        component: () => import("../views/subpages/AddProduct.vue"),
      },
      {
        path: "/product/edit",
        name: "editProduct",
        component: () => import("../views/subpages/EditProduct.vue"),
      },
      {
        path: "/inventory",
        name: "Inventory",
        component: () => import("../views/pages/Inventory.vue"),
      },
      {
        path: "/orders",
        name: "Orders",
        component: () => import("../views/pages/Orders.vue"),
      },
      {
        path: "/wallet",
        name: "Wallet",
        component: () => import("../views/pages/Wallet.vue"),
      },
      {
        path: "/withdraw-request",
        name: "Withdraw",
        component: () => import("../views/pages/Withdraw.vue"),
      },
      {
        path: "/reviews",
        name: "Reviews",
        component: () => import("../views/pages/Reviews.vue"),
      },
      {
        path: "/support",
        name: "Support",
        component: () => import("../views/pages/Support.vue"),
      },
      {
        path: "/flash-deal",
        name: "FlashDeal",
        component: () => import("../views/pages/FlashDeal.vue"),
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () => import("../views/pages/Notifications.vue"),
      },
      {
        path: "/account",
        name: "ChangePassword",
        component: () => import("../views/pages/ChangePassword.vue"),
      },

      {
        path: "/custom-orders",
        name: "Custom Orders",
        component: () => import("../views/pages/CustomOrders.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
