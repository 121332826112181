import Vue from "vue";
import Vuex from "vuex";

import axios_api from "../axios/axios_api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("v_token") || null,
    isAuthenticated: false,
    user: {},
    flashDeals: [],
    flashDealProducts: [],
    products: [],
    reviews: [],
    // stocks: [],
    categories: [],
    subcategories: [],
    subsubcategories: [],
    supports: [],
    brands: [],
    tags: [],
    colors: [],
    sizes: [],
    wallet: [],
    orders: [],
    withdrawRequests: [],
    bankDetails: [],
    uncompleteProduct: {},
    productCount: 0,
    reviewsCount: 0,
    notifications: [],
    customOrders: [],
    addVendor: {
      basicdetails: null,
      logo: null,
      document: null
    }
  },

  getters: {
    getFlashDeals(state) {
      return state.flashDeals;
    },
    getFlashDealProducts(state) {
      return state.flashDealProducts;
    },
    getProducts(state) {
      return state.products;
    },
    getStatus(state) {
      return state.orders.status;
    },
    getReviews(state) {
      return state.reviews;
    },

    getWithdraw(state) {
      return state.withdrawRequests;
    },

    getSupports(state) {
      return state.supports;
    },
    getBankDetail(state) {
      return state.bankDetails;
    },
    // getStocks(state) {
    //   return state.stocks;
    // },

    getpendingOrders(state) {
      return state.orders.filter(
        (item) =>
          item.status !== "New" &&
          item.status !== "Rejected" &&
          item.status !== "Delivered"
      );
    },
    getActiveOrders(state) {
      return state.orders.filter((item) => item.status === "New");
    },
    getcancelledOrders(state) {
      return state.orders.filter(
        (item) => item.status === "Rejected" && item.status !== "Cancelled"
      );
    },
    getcompletedOrders(state) {
      return state.orders.filter((item) => item.status === "Delivered");
    },

    getpendingCustomOrders(state) {
      return state.customOrders.filter(
        (item) =>
          item.status !== "New" &&
          item.status !== "Rejected" &&
          item.status !== "Delivered"
      );
    },
    getActiveCustomOrders(state) {
      return state.customOrders.filter((item) => item.status === "New");
    },
    getcancelledCustomOrders(state) {
      return state.customOrders.filter(
        (item) => item.status === "Rejected" && item.status !== "Cancelled"
      );
    },
    getcompletedCustomOrders(state) {
      return state.customOrders.filter((item) => item.status === "Delivered");
    },
  },

  mutations: {
    setToken(state, payload) {
      localStorage.setItem("v_token", payload ? payload : null);
      state.token = payload;
    },

    setAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    setStatus(state, payload) {
      const item = state.orders.find((item) => item.id === payload.id);
      Object.assign(item, payload);
    },

    setWallet(state, payload) {
      state.wallet = payload;
    },

    setOrders(state, payload) {
      state.orders = payload;
    },

    setUser(state, payload) {
      state.user = payload;
    },

    setFlashDeals(state, payload) {
      state.flashDeals = payload;
    },

    setFlashDealProduct(state, payload) {
      state.flashDealProducts = payload;
    },

    addFlashDealProduct(state, payload) {
      state.flashDealProducts.unshift(payload);
    },

    setProducts(state, payload) {
      state.products = payload;
    },

    setReviews(state, payload) {
      state.reviews = payload;
    },

    setBankDetails(state, payload) {
      state.bankDetails = payload;
    },
    setSupports(state, payload) {
      state.supports = payload;
    },
    updateSupports(state, payload) {
      state.supports.data = payload;
    },
    setSupportsCount(state, payload) {
      state.supports.supportCount = payload;
    },
    addSupport(state, payload) {
      state.supports.data.unshift(payload);
    },
    addBankDetail(state, payload) {
      state.bankDetails.unshift(payload);
    },
    // setStocks(state, payload) {
    //   state.stocks = payload;
    // },

    setColors(state, payload) {
      state.colors = payload;
    },

    setSizes(state, payload) {
      state.sizes = payload;
    },

    setCategories(state, payload) {
      state.categories = payload[0];
      state.subcategories = payload[1];
      state.subsubcategories = payload[2];
      // state.brands = payload[3];
      // state.tags = payload[4];
    },

    setVendorDetails(state, payload) {
      if (payload.type === null) {
        state.addVendor.basicdetails = payload;
        state.addVendor.logo = payload.logo;
        state.addVendor.document = payload.document;
      } else if (payload.type === "business") {
        state.addVendor.business_address = payload;
      } else if (payload.type === "warehouse") {
        state.addVendor.warehouse_address = payload;
      } else {
        state.addVendor.return_address = payload;
      }
    },

    setWithdrawRequest(state, payload) {
      state.withdrawRequests = payload;
    },
    addWithdrawRequest(state, payload) {
      state.withdrawRequests.unshift(payload);
    },
    setUncompleteData(state, payload) {
      state.uncompleteProduct = payload;
    },
    updateProduct(state, payload) {
      state.products.forEach((item) => {
        if (item.id === payload.id) {
          item = payload;
        }
      });
    },
    addProduct(state, payload) {
      state.products.unshift(payload);
    },
    setProductsCount(state, payload) {
      state.productCount = payload;
    },
    setReviewCount(state, payload) {
      state.reviewsCount = payload;
    },

    setNotification(state, payload) {
      state.notifications = payload;
    },

    setBrands(state, payload) {
      state.brands = payload;
    },

    setTags(state, payload) {
      state.tags = payload;
    },

    setCustomOrders(state, payload) {
      state.customOrders = payload;
    },
    setCustomStatus(state, payload) {
      const item = state.customOrders.find((item) => item.id === payload.id);
      Object.assign(item, payload);
    },
  },

  actions: {
    storeFlashDealProduct: function ({ state, commit }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("/add/product/flash-deal", data)
            .then((response) => {
              commit("addFlashDealProduct", response.data.data);
              resolve(response.data.data);
            })
            .catch((error) => reject(error));
        });
      }
    },
    getFlashDealProducts({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        if (state.user) {
          return new Promise((resolve, reject) => {
            axios_api
              .get("vendor/flashdeal-participants")
              .then((response) => {
                commit("setFlashDealProduct", response.data);
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          });
        }
      }
    },

    getProducts(context) {
      if (context.state.user) {
        return new Promise((resolve, reject) => {
          axios_api
            .get("product")
            .then((response) => {
              context.commit("setProducts", response.data.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },

    getBankDetails({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("bank-details")
            .then((response) => {
              commit("setBankDetails", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },

    storeBankDetails({ state, commit }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          commit("addBankDetail", data);
          axios_api
            .post("bank-details", data)
            .then((response) => {
              if (response.data.status === 200) resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateBankDetails: function ({ state }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .put(`/bank-details/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    deleteBankDetails({ state }, id) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/bank-details/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },

    getReviews({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("vendor-reviews")
            .then((response) => {
              commit("setReviews", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    replyToReview({ state }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .put(`/reviews/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },

    // getStocks(context) {
    //   if (context.state.user) {
    //     return new Promise((resolve, reject) => {
    //       axios_api
    //         .get("stocks")
    //         .then((response) => {
    //           context.commit("setStocks", response.data);
    //           resolve(response.data);
    //         })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //     });
    //   }
    // },
    updateStock: function (context, data) {
      if (context.state.user) {
        return new Promise((resolve, reject) => {
          axios_api
            .put(`/stocks/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getSupports(context) {
      if (context.state.user) {
        return new Promise((resolve, reject) => {
          axios_api
            .get("support")
            .then((response) => {
              context.commit("setSupports", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateOrderStatus: function ({ state }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          axios_api
            .put(`/order-item/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    updateSupport: function ({ state }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        data._method = "PUT";
        return new Promise((resolve, reject) => {
          axios_api
            .put(`/support/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    storeSupport: function ({ state }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        let fd;

        fd = new FormData();
        fd.append("image", data.image);
        fd.append("category", data.category);
        fd.append("type", data.type);
        fd.append("subject", data.subject);
        fd.append("description", data.description);

        return new Promise((resolve, reject) => {
          axios_api
            .post("support", fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },

    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios_api
          .post("login", data)
          .then((response) => {
            if (response.data) {
              commit("setToken", response.data.token);
              commit("setAuthenticated", true);
              commit("setUser", response.data.user);
            }
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUser({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("getUser")
            .then((res) => {
              commit("setUser", res.data.user);
              commit("setAuthenticated", true);
              resolve(res);
            })
            .catch((error) => reject(error));
        });
      }
    },
    getColors({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("colors")
            .then((response) => {
              commit("setColors", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getSizes({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("sizes")
            .then((response) => {
              commit("setSizes", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getFlashDeals({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("flash-deal")
            .then((response) => {
              commit("setFlashDeals", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    // getFlashDeals(context) {
    //   if (context.state.user) {
    //     return new Promise((resolve, reject) => {
    //       axios_api
    //         .get("/upcoming/flash-deal")
    //         .then((response) => {
    //           context.commit("setFlashDeals", response.data);
    //           resolve(response.data);
    //         })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //     });
    //   }
    // },
    getWithdrawRequest({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("withdraw-request-by-vendor")
            .then((response) => {
              commit("setWithdrawRequest", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },

    storeWithdrawRequest: function ({ state, commit }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("/withdraw-request", data)
            .then((response) => {
              commit("addWithdrawRequest", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },

    prepareVendorDetails({ commit }, data) {
      commit("setVendorDetails", data);
    },
    getVendorProducts({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("vendor-products")
            .then((response) => {
              commit("setProducts", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    deleteProduct({ state }, id) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .delete(`/products/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    getVendorReviews({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("vendor-reviews")
            .then((response) => {
              commit("setReviews", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getWallet({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("wallet")
            .then((response) => {
              commit("setWallet", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getOrders({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("vendor-orders")
            .then((response) => {
              commit("setOrders", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },

    getCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("categories")
          .then((response) => {
            commit("setCategories", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addProduct({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        axios_api.defaults.headers.common["Content-Type"] =
          "multipart/form-data";
        return new Promise((resolve, reject) => {
          axios_api
            .post("products", data)
            .then((response) => {
              resolve(response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    productadd({ state, commit }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("product-complete", {
              id: data,
            })
            .then((response) => {
              commit("updateProduct", response.data);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    storeVendor({ state }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
      }
      axios_api.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append(
          "basicdetails",
          JSON.stringify(state.addVendor.basicdetails)
        );
        formdata.append(
          "business_address",
          JSON.stringify(state.addVendor.business_address)
        );
        formdata.append(
          "warehouse_address",
          JSON.stringify(state.addVendor.warehouse_address)
        );
        formdata.append(
          "return_address",
          JSON.stringify(state.addVendor.return_address)
        );
        formdata.append("logo", state.addVendor.basicdetails.logo);
        formdata.append("document", state.addVendor.basicdetails.document);
        axios_api
          .post("post-vendor", formdata)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUncompleteData({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("product-uncomplete")
            .then((response) => {
              commit("setUncompleteData", response.data);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getProductsCount({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          let url = "/v-products/count";
          axios_api
            .get(url)
            .then((response) => {
              commit("setProductsCount", response.data.count);
              resolve(response.data.count);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getReviewCount({ state, commit }) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          let url = "/v-review-count";
          axios_api
            .get(url)
            .then((response) => {
              commit("setReviewCount", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getAllBrands({ commit }) {
      return new Promise((resolve, reject) => {
        let url = "/all-brands";
        axios_api
          .get(url)
          .then((response) => {
            commit("setBrands", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUnreadNotification({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("unread/notifications")
          .then((response) => {
            commit("setNotification", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllNotification() {
      return new Promise((resolve, reject) => {
        axios_api
          .get("notifications/all")
          .then((response) => {
            // commit("setNotification", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAllTags({ commit }) {
      return new Promise((resolve, reject) => {
        let url = "/all-tags";
        axios_api
          .get(url)
          .then((response) => {
            commit("setTags", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    markAsRead() {
      return new Promise((resolve, reject) => {
        axios_api
          .get("notifications/read")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBrands({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("all-brands")
          .then((response) => {
            commit("setBrands", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTags({ commit }) {
      return new Promise((resolve, reject) => {
        axios_api
          .get("all-tags")
          .then((response) => {
            commit("setTags", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changePassword({ state }, payload) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          let url = "/change-password";
          axios_api
            .post(url, payload)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getCustomOrders({ state, commit }) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .get("vendor-custom-orders")
            .then((response) => {
              commit("setCustomOrders", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    updateCustomOrderStatus: function ({ state }, data) {
      if (state.user) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post(`/custom-order-item/${data.id}`, data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
    updateProductStatus({ state }, data) {
      if (state.token) {
        axios_api.defaults.headers.common["Authorization"] =
          "Bearer " + state.token;
        return new Promise((resolve, reject) => {
          axios_api
            .post("/change-status/product", data)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      }
    },
  },
});
